<template>
  <div class="update-credit-payment">
      <div class="header">
          <h2>בקשה לשינוי תנאי תשלום ללקוח</h2>
          <p>סוכן: {{agent_name}}, <span>מס: {{agent_number}}</span></p>
      </div>
      <div class="content">
           <div v-if="!client_data" class="field">
                <p>רשום מספר לקוח</p>
                <el-input v-model="client_number"  placeholder="מספר לקוח" />
                <el-button @click="get_client_from_db" style="width:100%; margin-top:5px;" type="primary">בחר לקוח</el-button>
            </div>
            <div v-if="client_data" class="client-display slide-in-top">
                <p>מספר לקוח:{{client_data.makat}}</p>
                <p>שם לקוח:{{client_data.name}}</p>
                <p>ח.פ לקוח:{{client_data.vat}}</p>
                <p>מגזר עסקי:{{client_data.migzar}}</p>
                <p>אובליגו קיים:{{client_data.obligo}}</p>
                <p>אשראי קיים:{{client_data.payment}}</p>
                <div class="change-client">
                    <el-button @click="handle_change_client" style="width:100%;" type="primary">החלף לקוח</el-button>
                </div>
            </div>
            <div v-if="client_data" class="field">
                <p>אובליגו</p>
                <el-select style="width:100%;" v-model="values.obligo_request" placeholder="אובליגו מבוקש">
                    <el-option
                        v-for="option in obligo_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.obligo_request=='בקשה לשינוי'" class="field">
                <p>הזן אובליגו חדש</p>
                <el-input v-model="values.new_obligo"  placeholder="הזן אובליגו חדש" />
            </div>
            <div  v-if="client_data"  class="field">
                <p>אשראי</p>
                <el-select style="width:100%;" v-model="values.credit_request" placeholder="אשראי מבוקש">
                    <el-option
                        v-for="option in obligo_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.credit_request=='בקשה לשינוי'" class="field">
                <p>הזן אשראי חדש</p>
                <el-input v-model="values.new_credit" placeholder="הזן אשראי חדש" />
            </div>
            <div  v-if="client_data" class="field">
                <p>אופן התשלום</p>
                <el-select style="width:100%;" v-model="values.payment_request" placeholder="אשראי מבוקש">
                    <el-option
                        v-for="option in obligo_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div v-if="values.payment_request=='בקשה לשינוי'"  class="field">
                <p>בחר תשלום מבוקש</p>
                <el-select style="width:100%;" v-model="values.new_payment" placeholder="בחר תשלום מבוקש">
                    <el-option
                        v-for="option in payment_options"
                        :key="option"
                        :value="option"
                    >
                    </el-option>
                </el-select>
            </div>
            <div  v-if="client_data" class="field">
                <p>פירוט הסיבה לבקשה</p>
                <el-input
                    v-model="values.reason"
                    :rows="3"
                    type="textarea"
                    placeholder="פירוט הסיבה לבקשה"
                />
            </div>
            <div v-if="client_data" class="field">
                 <el-button @click="handle_submit" style="width:100%;" type="primary">שלח בקשה</el-button>
            </div>
      </div>
        <!-- just for mail -->
        <div class="form-email" id="form-email" v-show="false">
            <table
                style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
            >
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        נוצר בתאריך
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ new Date().toLocaleDateString('he') }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        שם הסוכן
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ agent_name }}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        מספר סוכן
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ agent_number }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        מספר לקוח
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ client_number }}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        אובליגו
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <p v-if="!values.new_obligo">ללא שינוי</p>
                        <p v-else>{{values.new_obligo}}</p>
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        אשראי
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <p v-if="!values.new_credit">ללא שינוי</p>
                        <p v-else>{{values.new_credit}}</p>
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        אופן התשלום
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <p v-if="!values.new_payment">ללא שינוי</p>
                        <p v-else>{{values.new_payment}}</p>
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        פירוט הסיבה לבקשה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <p>{{values.reason}}</p>
                    </td>
                </tr>
            </table>
        </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore,projectFunctions} from '../../firebase/config'
import {slide_pop_error,alert} from '../../Methods/Msgs'
import store from '../../store'
import { computed } from '@vue/runtime-core'

export default {
    setup(){
        const agent_number = ref(computed(()=>{
            if(store.getters.user){
                return store.getters.user.agent
            }
        }))
        const agent_name = ref(computed(()=>{
            if(store.getters.user){
                return store.getters.user.display_name
            }
        }))
        const error_msg = ref('')
        const client_number = ref('')
        const client_data = ref(null)
        const obligo_options = ref([
            'ללא שינוי',
            'בקשה לשינוי'
        ])
        const payment_options = ref([
            'חתימה',
            'שיק לנהג',
            'מזומן לנהג'
        ])
        const values = ref({
            obligo_request:'',
            new_obligo:'',
            credit_request:'',
            new_credit:'',
            payment_request:'',
            new_payment:'',
            reason:''
        })
        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'obligo_request':
                        if(value=='בקשה לשינוי'){
                            if(!values.value.new_obligo){
                                error_msg.value = 'עליך להזין אובליגו חדש'
                                return false
                            }
                        }
                        break;
                    case 'credit_request':
                        if(value=='בקשה לשינוי'){
                            if(!values.value.new_credit){
                                error_msg.value = 'עליך להזין אשראי חדש'
                                return false
                            }
                        }
                        break;
                    case 'payment_request':
                        if(value=='בקשה לשינוי'){
                            if(!values.value.new_payment){
                                error_msg.value = 'עליך להזין אופן תשלום חדש'
                                return false
                            }
                        }
                        break;
                    case 'reason':
                        if(!value){
                            error_msg.value = 'עליך להזין את הסיבה לבקשה זו'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const validation_2 =()=>{
            if((values.value.obligo_request=='ללא שינוי'|| !values.value.obligo_request)
            && (values.value.credit_request=='ללא שינוי' || !values.value.credit_request ) 
            && (values.value.payment_request=='ללא שינוי'||!values.value.payment_request)){
                error_msg.value = 'אינך יכול לשלוח טופס ללא שינויים'
                return false
            }
            return true
        }
        const handle_submit = ()=>{
            if( !validation_2()){
                slide_pop_error(error_msg.value)
            }else{
                if(!validation()){
                    slide_pop_error(error_msg.value)
                }else{
                    sendEmail()
                    alert('success','תודה רבה','הטופס נשלח בהצלחה')
                    .then(()=>{
                        reset_values()
                        client_data.value = null
                        client_number.value=''
                    })
                    console.log(values.value);
                }
            }
        }
        const handle_change_client = ()=>{
            client_data.value = null
            reset_values()
        }
        const reset_values = ()=>{
            values.value = {
                obligo_request:'',
                new_obligo:'',
                credit_request:'',
                new_credit:'',
                payment_request:'',
                new_payment:'',
                reason:''
            }
        }
        const get_client_from_db = async()=>{
            if(client_number.value){
                const doc =  await projectFirestore.collection('Clients').doc(client_number.value).get()
                if(doc.exists){
                    client_data.value = doc.data()
                    console.log(client_data.value);
                }else{
                    slide_pop_error(`לקוח מספר ${client_number.value} לא קיים במערכת`)
                }

            }
        }

        function sendEmail() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:['eldad@rosman.co.il','nir.rosman@gmail.com','yavrosman@gmail.com '],
              from:"פורטל סוכנים",
              title:`נוצרה בקשה לשינוי תנאי תשלום, סוכן:${agent_name.value}, לקוח:${client_number.value}`
          })
        }
        return{agent_number,agent_name,error_msg,handle_submit,handle_change_client,get_client_from_db,client_number,client_data,values,obligo_options,payment_options}
    }
}
</script>

<style scoped>
    .update-credit-payment{
        width: 100%;
        height: 100%;
        background-color: var(--secondary);
        color: #fff; 
    }
    .header{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .content{
        margin: 0 auto;
        width: 100%;
        max-width: 700px;
        height: 90%;
        overflow:hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 18px;
    }
    .content .field{
        padding: 10px;
        margin-top: 10px;
        width: 100%;
        height: fit-content;
    }
    .client-display{
        position: relative;
        padding: 10px;
        width: 100%;
        height: 300px;
        background-color: var(--main);
        border-radius: 20px;
        font-size: 25px;
    }
    .change-client{
        position: absolute;
        bottom: 0;
        left: 10px;
        width: 30%;
        height: 15%;
    }
    .client-display p{
        line-height: 1.8;
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }

</style>